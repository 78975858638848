import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import config from "../../../package.json";
import CatBanner from "./CatBanner";
import Content from "./Content";
import LoaderBanner from "./LoaderBanner";
import LoaderContent from "./LoaderContent";
import { Helmet, HelmetProvider } from "react-helmet-async";
const Detail = () => {
  let params = useParams();
  let par = params.pageiId;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  useEffect(() => {
    let url = config.apiurl + "getPostName?pageId=" + par;
    fetch(url, {})
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [par]);
  if (isLoading) {
    return (
      <>
        <LoaderBanner />
        <LoaderContent />
      </>
    );
  } else {
    return (
      !isLoading && (
        <>
          <HelmetProvider>
            <Helmet>
              <title>{data.postData.post_title}</title>
              <meta
                name="description"
                content="Contact us if you have any question or requests"
              />
            </Helmet>
            <CatBanner title={data.postData.post_title} />
            <Content posts={data} />
          </HelmetProvider>
        </>
      )
    );
  }
};

export default Detail;
