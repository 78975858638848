import React, { Component } from "react";
import {Link} from "react-router-dom";
class Postlist extends Component {
    
    render() {
        const { allpost , title} = this.props;

        return (
          <> 

            <div className="container-xxl py-1">
    <div className="container">
        <h1 className="text-center mb-3 wow fadeInUp texttransform" data-wow-delay="0.1s">{title}</h1>
        <div className="tab-className text-center wow fadeInUp" data-wow-delay="0.3s">

        {allpost.data.map((menu) => {
            const date = new Date(menu.post_modified_gmt);
            const options = { month: 'long', day: 'numeric', year: 'numeric' };
            const formattedDate = date.toLocaleDateString(undefined, options);
            return (
                
            <div key={menu.post_title} className="job-item p-4 mb-4">
                    <div className="row g-4">
                        <div className="col-sm-12 col-md-8 d-flex align-items-center">
                            <img className="flex-shrink-0 img-fluid border rounded" src={require('../../img/'+title+'.png')} alt="magento_icon.png" style={{'width':80,'height':80}} />
                            <div className="text-start ps-4">
                                <h5 className="mb-3">{menu.post_title}</h5>
                                
                                
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
                            <div className="d-flex mb-3">
                                
                                <Link key={menu.post_title} to={"/post/"+menu.post_name} className="btn btn-primary">Show Details</Link>
                            </div>
                            <small className="text-truncate"><i className="far fa-calendar-alt text-primary me-2"></i>{formattedDate}</small>
                        </div>
                    </div>
                </div>
            )
            })}
        </div>
    </div>
    </div>
        </>
        )
    }
}
export default Postlist;
