import React, { Component } from "react";
class Api extends Component {
    render() {
        const { posts } = this.props;
        return (
           <>
           <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <div className="row g-0 about-bg rounded overflow-hidden">
                                <img  alt="{posts.image}" className="img-fluid w-100"  src={posts &&  (posts.image)} />
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <h1 className="mb-4">{posts && (<div dangerouslySetInnerHTML={{ __html: posts.post_title}} />)}</h1>
                            {posts && (<div dangerouslySetInnerHTML={{ __html: posts.post_content}} />)}
                            <a className="btn btn-primary py-3 px-5 mt-3" href="/">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
           </>
        )
    }
}
export default Api;