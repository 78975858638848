import React, { Component } from "react";
import { Link } from "react-router-dom";
class Content extends Component {
  render() {
    const { posts } = this.props;
    let post = posts.postData;
    const date = new Date(post.post_date);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString(undefined, options);
    return (
      <>
        <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row gy-5 gx-4">
              <div className="col-lg-9">
                <div className="d-flex align-items-center mb-5">
                  <img
                    className="flex-shrink-0 img-fluid border rounded"
                    src={require("../../img/magento.png")}
                    alt="com-logo-2.jpg"
                    style={{ width: 80, height: 80 }}
                  />
                  <div className="text-start ps-4">
                    <h3 className="mb-3">{post.post_title}</h3>
                    <span className="text-truncate me-3">
                      <i className="fa fa fa-user text-primary me-2"></i>{post.auther}
                    </span>
                    <span className="text-truncate me-3">
                      <i className="far fa-calendar text-primary me-2"></i>
                      {formattedDate}
                    </span>
                  </div>
                </div>

                <div className="mb-5">
                  <div
                    dangerouslySetInnerHTML={{ __html: post.post_content }}
                  />
                </div>
              </div>

              <div className="col-lg-3 fixed-sidebar">
                <div
                  className="bg-light rounded p-3 mb-4 wow slideInUp"
                  data-wow-delay="0.1s"
                >
                  <h4 className="mb-4">Releted Issues</h4>
                  {posts.reletedPost.map((menu) => {
                    return (
                      <Link
                        key={menu.post_name}
                        to={"/post/" + menu.post_name}
                        className="nav-item nav-link active"
                      >
                        {menu.post_title}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Content;
