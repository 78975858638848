import { useEffect, React } from "react";

import $ from "jquery";

import Menu from "./Menu";

function Header() {
  useEffect(() => {
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 300) {
        $(".sticky-top").css("top", "0px");
      } else {
        $(".sticky-top").css("top", "-100px");
      }
    });
  });
  return (
    <>
      <Menu></Menu>
    </>
  );
}
export default Header;
