import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
class LoaderList extends Component {
  render() {
    return (
      <>
        <div className="container-xxl py-1">
          <div className="container loading-skeleton">
            <h1
              className="text-center mb-3 wow fadeInUp texttransform"
              data-wow-delay="0.1s"
            >
              <SkeletonTheme color="#00B074" highlightColor="#00B074">
                <Skeleton />
              </SkeletonTheme>
            </h1>
            <div
              className="tab-className text-center wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="job-item p-4 mb-4">
                <div className="row g-4">
                  <div className="col-sm-12 col-md-8 d-flex align-items-center">
                    <SkeletonTheme color="#00B074" highlightColor="#00B074">
                      <Skeleton width={80} height={80} />
                    </SkeletonTheme>

                    <div className="text-start ps-4">
                      <SkeletonTheme color="#00B074" highlightColor="#00B074">
                        <Skeleton height={10} />
                      </SkeletonTheme>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
                    <div className="d-flex mb-3">
                      {" "}
                      <SkeletonTheme color="#00B074" highlightColor="#00B074">
                        <Skeleton height={10} />
                      </SkeletonTheme>
                    </div>
                    <small className="text-truncate">
                      <i className="far fa-calendar-alt text-primary me-2"></i>
                    </small>
                  </div>
                </div>
              </div>
              <div className="job-item p-4 mb-4">
                <div className="row g-4">
                  <div className="col-sm-12 col-md-8 d-flex align-items-center">
                    <SkeletonTheme color="#00B074" highlightColor="#00B074">
                      <Skeleton width={80} height={80} />
                    </SkeletonTheme>

                    <div className="text-start ps-4">
                      <SkeletonTheme color="#00B074" highlightColor="#00B074">
                        <Skeleton height={10} />
                      </SkeletonTheme>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
                    <div className="d-flex mb-3">
                      {" "}
                      <SkeletonTheme color="#00B074" highlightColor="#00B074">
                        <Skeleton height={10} />
                      </SkeletonTheme>
                    </div>
                    <small className="text-truncate">
                      <i className="far fa-calendar-alt text-primary me-2"></i>
                    </small>
                  </div>
                </div>
              </div>
              <div className="job-item p-4 mb-4">
                <div className="row g-4">
                  <div className="col-sm-12 col-md-8 d-flex align-items-center">
                    <SkeletonTheme color="#00B074" highlightColor="#00B074">
                      <Skeleton width={80} height={80} />
                    </SkeletonTheme>

                    <div className="text-start ps-4">
                      <SkeletonTheme color="#00B074" highlightColor="#00B074">
                        <Skeleton height={10} />
                      </SkeletonTheme>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
                    <div className="d-flex mb-3">
                      {" "}
                      <SkeletonTheme color="#00B074" highlightColor="#00B074">
                        <Skeleton height={10} />
                      </SkeletonTheme>
                    </div>
                    <small className="text-truncate">
                      <i className="far fa-calendar-alt text-primary me-2"></i>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default LoaderList;
