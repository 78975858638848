import React, { Component } from 'react'
import Slider from './Slider';
import Searchbox from './Searchbox';
import Homeabout from './Homeabout';
export class Homemid extends Component {
  render() {
    return (
        <>
        <Slider/>
        <Searchbox/>
        <Homeabout/>
        </>
    )
  }
}

export default Homemid
