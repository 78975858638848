import React, { Component } from "react";
class Banner extends Component {
    render() {
        const { title , bannerimage} = this.props;
        const styles = {
              backgroundImage: `url(${bannerimage})` 
        }
        return (
          <> 
            <div 
            style={styles}
            className="container-xxl py-5 bg-dark  mb-5">
                <div className="container my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">{title}</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb text-uppercase">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item"><a href="/">Pages</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">{title}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </>
        )
    }
}
export default Banner;
