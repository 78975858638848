import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CatBanner from "./CatBanner";
import Postlist from "./Postlist";
import config from "../../../package.json";

import axios from "axios";
import LoaderBanner from "./LoaderBanner";
import LoaderList from "./LoaderList";
const Cat = () => {
  
  let params = useParams();
  let par=params.pageiId;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  useEffect(() => {
    let url=config.apiurl+"getPageByCat?pageId="+par;
    axios({
      url: url,
      method: "GET"
    })
      .then((response) => {
        setData(response);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [par]);
  if(isLoading){
    
    return ( <>
      <LoaderBanner />
      <LoaderList />
    </>);
  }else{
    return (!isLoading && (
      <>
        <CatBanner title={par}  />
        <Postlist allpost={data} title={par} />
      </>
    ))
  }

}


export default Cat
