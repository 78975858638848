import React, { useEffect, useState} from 'react'
//import { Parser } from 'html-to-react'
import {Link} from "react-router-dom";
import config from "../../package.json";
const Footermenu = (setMenuData) => {

   // const [getMetaData, setMenuData] = useState([]);
   
    const scrollToTop = () => {window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });};
    const [menus, setMenus] = useState([]);
  useEffect(() => {
  // setMenuData(menudata);
   let menuId=setMenuData.menuid;
    if(menuId){
    fetch(config.apiurl+'menu?menu_id='+menuId)
       .then((response) => response.json())
       .then((data) => {
          setMenus(data);
       })
       .catch((err) => {
          console.log(err.message);
       });
      }
    }, [setMenuData]);
   
   return(
    <>
    {menus.map((menu) => {
        return (
          <Link onClick={scrollToTop} key={menu.title} to={setMenuData.pagetype+Repace(menu.url)} className="btn btn-link text-white-50">{menu.title}</Link>
        );
      })}
    </>
   );
  
}
const Repace = (url) => {
   let menuurl = new URL(url);
   return menuurl.pathname;
}


export default Footermenu
