import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
class LoaderBanner extends Component {
  render() {
    return (
      <SkeletonTheme color="#00B074" highlightColor="#00B074">
        <Skeleton height={190} />
      </SkeletonTheme>
    );
  }
}
export default LoaderBanner;
