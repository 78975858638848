import React, { useEffect, useState } from "react";
//import { Parser } from 'html-to-react'
import { Link } from "react-router-dom";
import config from "../../package.json";
import LoaderMenu from "./LoaderMenu";
const Menu = () => {
  const [menus, setMenus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(config.apiurl + "menu?menu_id=5")
      .then((response) => response.json())
      .then((data) => {
        setMenus(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  if (isLoading) {
    return (
      <>
        <LoaderMenu />
      </>
    );
  } else {
    return (
      <>
        <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
          <a
            href="/"
            className="navbar-brand d-flex align-items-center text-center py-0 px-4 px-lg-5"
          >
            <h1 className="m-0 text-primary">Super One</h1>
          </a>
          <button
            type="button"
            className="navbar-toggler me-4"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
              {menus.map((menu) => {
                return (
                  <Link
                    key={menu.title}
                    to={Repace(menu.url)}
                    className="nav-item nav-link active"
                  >
                    {menu.title}
                  </Link>
                );
              })}
            </div>
            <div className="rounded-0 py-4 px-lg-5 d-none d-lg-block"></div>
          </div>
        </nav>
      </>
    );
  }
};
const Repace = (url) => {
  let menuurl = new URL(url);
  return menuurl.pathname;
};
export default Menu;
