import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Banner from './Banner'
import Api from './Api'


const About = () => {
  let params = useParams();
  let par=params.pageiId;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  useEffect(() => {
    console.log(par);
    let url="http://wordpress.local/wp-json/wp/v2/getPageByName?pageId="+par;
    fetch(url, {})
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setIsLoading(false);
        console.log(url);
      })
      .catch((error) => console.log(error));
  }, [par]);

  return (!isLoading && (
    <>
      <Banner title={data.post_title} bannerimage={data.banner_images} />
      <Api  posts={data} />
    </>
  ))
}


export default About
