import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
class LoaderMenu extends Component {
  render() {
    return (
      <>
        <div className="container-fluid my-1 shadow sticky-top">
          <div className="row">
            <div className="col-md-4">
              <SkeletonTheme color="#00B074" highlightColor="#00B074">
                <Skeleton height={40} />
              </SkeletonTheme>
            </div>
            <div className="col-md-8">
              <SkeletonTheme color="#00B074" highlightColor="#00B074">
                <Skeleton count={2} />
              </SkeletonTheme>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default LoaderMenu;
