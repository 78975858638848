import React, { Component } from "react";
class CatBanner extends Component {
    render() {
        const { title } = this.props;
        return (
          <>
            <div className="container-xxl py-1 bg-dark  mb-5 page-header">
            
                <div className="container my-1 pt-5 pb-1">
                    <h1 className="display-6 text-white mb-3 animated slideInDown texttransform">{title}</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb text-uppercase">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item"><a href="/">Pages</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">{title}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </>
        )
    }
}
export default CatBanner;
