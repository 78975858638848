import React from 'react';
import ReactDOM from 'react-dom/client';
import './Css/style.css';
import Homemid from './component/Home/Homemid';
import reportWebVitals from './reportWebVitals';

import Header from './component/Header';
import Footer from './component/Footer';
import About from './component/About/About';
import Cat from './component/Category/Cat';
import Detail from './component/Category/Detail';
import {
  Routes,
  Route,
  BrowserRouter,
  
} from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    
    <BrowserRouter>
    <Header></Header>
    <Routes>
        <Route path="/" element={<Homemid />}/>
        <Route path="pages/:pageiId" element={<About />}/>
        <Route path="category/:pageiId" element={<Cat />}/>
        <Route path="post/:pageiId" element={<Detail />}/>
      </Routes>
    <Footer></Footer>
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();




