import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
class LoaderContent extends Component {
  render() {
    return (
      <>
        <div
          className="container-xxl py-5 wow fadeInUp"
          data-wow-delay="0.1s loading-skeleton"
        >
          <div className="container">
            <div className="row gy-5 gx-4">
              <div className="col-lg-9">
                <div className="d-flex align-items-center mb-5">
                  <SkeletonTheme color="#00B074" highlightColor="#00B074">
                    <Skeleton width={80} height={80} />
                  </SkeletonTheme>
                  <div className="text-start ps-4">
                    <h3 className="mb-3">
                    <SkeletonTheme color="#00B074" highlightColor="#00B074">
                      <Skeleton height={30} width={600} />
                      </SkeletonTheme>
                    </h3>
                    <span className="text-truncate me-3">
                      <i className="fa fa fa-user text-primary me-2"><Skeleton width={100} /></i>
                    </span>
                    <span className="text-truncate me-3">
                      <i className="far fa-calendar text-primary me-2"> <Skeleton width={100} /></i>
                    </span>
                  </div>
                </div>

                <div className="mb-5">
                <SkeletonTheme color="#00B074" highlightColor="#00B074">
                  <Skeleton height={5} count={10} />
                  </SkeletonTheme>
                </div>
              </div>

              <div className="col-lg-3 fixed-sidebar">
                <div
                  className="bg-light rounded p-3 mb-4 wow slideInUp"
                  data-wow-delay="0.1s"
                >
                  <h4 className="mb-4">
                  <SkeletonTheme color="#00B074" highlightColor="#00B074">
                    <Skeleton width={200} />
                    <Skeleton height={5} count={10} />
                  </SkeletonTheme>
                   
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default LoaderContent;
